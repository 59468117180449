<template>
  <div class="admin-edit-incident-type-group">
    <loading-screen :is-loading="isSaving || isDeleting"></loading-screen>

    <div class="page-header">
      <h1 class="page-title">Edit Incident Type Group</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >

        <a-popconfirm
          v-if="!isLoading && incidentTypeGroup"
          title="Are you sure?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="attemptDelete"
        >
          <a-button
            class="button-margin-left btn-rounded"
            icon="delete"
            size="large"
            type="danger"
            >Delete</a-button
          >
        </a-popconfirm>
      </div>
    </div>

    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- Form -->
    <div v-if="!isLoading && incidentTypeGroup" class="form">
      <a-form-item label="Incident Type Group Name">
        <a-input size="large" v-model="incidentTypeGroup.displayName"></a-input>
      </a-form-item>

      <a-form-item label="Visibility">
        <a-radio-group v-model="incidentTypeGroup.scope">
          <a-radio :value="1">Shown to organisation</a-radio>
          <!-- <a-radio :value="2">Shown to whole tenant</a-radio> -->
          <a-radio :value="3">Hidden from lists</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="Display Order">
        <a-radio-group v-model="incidentTypeGroup.rank">
          <a-radio v-for="(rank, i) in ranks" :key="i" :value="rank.rank">{{
            rank.label
          }}</a-radio>
        </a-radio-group>
      </a-form-item>
    </div>
    <!-- / Form -->

    <a-button
      v-if="!isLoading && incidentTypeGroup"
      @click.prevent="save"
      class="btn-rounded save-button"
      size="large"
      type="primary"
      >Save</a-button
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import guidanceHelpers from "../../../../helpers/guidance";
import incidentTypes from "../../../../api/incident-types";
export default {
  components: { LoadingScreen },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      incidentTypeGroup: null,
    };
  },
  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    } else {
      this.loadIncidentTypeGroup();
    }
  },
  methods: {
    goBack() {
      this.$router.push("/admin/settings/incident-type-groups");
    },

    loadIncidentTypeGroup() {
      let vm = this;
      vm.isLoading = true;
      vm.incidentTypeGroup = null;
      incidentTypes
        .getIncidentType(this.tenantId, this.$route.params.id)
        .then((r) => {
          vm.isLoading = false;
          vm.incidentTypeGroup = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          vm.$message.error("Incident type group not found");
          vm.$router.push("/admin/settings/incident-type-groups");
        });
    },

    getValidationErrors() {
      let errors = [];
      if (this.incidentTypeGroup.displayName.trim().length == 0) {
        errors.push("Please provide a group name");
      }
      return errors;
    },

    save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      let params = {
        ...this.incidentTypeGroup,
        ownerId: this.selectedOrganisation.id,
        purpose: 2,
      };

      vm.isSaving = true;
      incidentTypes
        .updateIncidentType(this.tenantId, params)
        .then(() => {
          vm.isSaving = false;
          vm.$message.success("Group updated successfully");
          vm.$router.push("/admin/settings/incident-type-groups");
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$message.error("Error updating group");
        });
    },

    attemptDelete() {
      let vm = this;
      vm.isDeleting = true;
      incidentTypes
        .deleteIncidentType(this.tenantId, this.$route.params.id)
        .then(() => {
          vm.isDeleting = false;
          vm.$message.success("Group deleted successfully");
          vm.$router.push("/admin/settings/incident-type-groups");
        })
        .catch((e) => {
          console.log(e);
          vm.isDeleting = false;
          vm.$message.error("Error deleting group");
        });
    },
  },
  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
    }),

    ranks() {
      return guidanceHelpers.listRankOptions();
    },
  },
};
</script>

<style scoped lang="scss">
.admin-edit-incident-type-group {
  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .top-row {
    display: flex;
    .left {
      flex-shrink: 1;
    }
    .right {
      padding-left: 20px;
      flex-grow: 1;
    }
  }

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 25px;
  }
}
</style>